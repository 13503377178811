import { DateTime } from 'luxon';

export const formatDate = (stringValue, localization = 'pt-BR') => {
    return new Date(stringValue).toLocaleDateString(localization, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
  };

  export const formatDateAndHours = (stringValue, localization = 'pt-BR') => {
    return new Date(stringValue).toLocaleDateString(localization, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour:'numeric',
        minute:'numeric',
        second:'numeric'
    });
  };

export const formaDateSimple = (stringValue) => {
    return new Date(stringValue).toLocaleDateString()
}

export const dateFromIso = (dateobj, localization = null) => {
    return DateTime.fromISO(dateobj, {setZone: true}).toLocaleString(DateTime.DATE_SHORT, {locale: localization})
}

// localization = 'pt-BR'
export const datetimeOrDateFromIso = (dateobj, timezone = 'local', localization = 'pt-BR') => {
    let date = DateTime.fromISO(dateobj, { setZone: true })
    if (timezone){
        date = date.setZone(timezone);
    }
    return date.toLocaleString(DateTime.DATETIME_SHORT, { locale: localization });
}

export const datetimeFromIso = (dateobj, setZone = true) => {
    return DateTime.fromISO(dateobj, {setZone: setZone}).toString(DateTime.DATETIME_SHORT)
}