
import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "components/utils/Loader"
import { withRouter, Link } from "react-router-dom";
import { updateNf, getNf, uploadCollect } from "../../components/nf/NfActions";
import { fileToSha256Hex } from "../../utils/sha256";
import { putBlankProperties, fixBlankProperties, toastOnError } from "../../utils/Utils";
import { FileEarmarkArrowDownFill } from 'react-bootstrap-icons';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    InputGroup,
    Row,
    Col,
    Modal,
    Table,
    Accordion
} from "react-bootstrap";

class AddNFXml extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            dc: {
                id: "",
                number: "1",
                file_path: "",
                type: "",
                issued_at: "2024-07-28",
            },
            validated: false,
            loading: false,
            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: "",
            fileInfos: [],
        }
        this.props.nfsReduc.nf = this.state.dc
        this.modalVerify()
    }

    setValidated = (v) => {
        this.setState({ validated: v });
    }

    modalVerify = () => {

    }

    handleSubmit = (event) => {
        const form = document.getElementById("addNfForm")
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setValidated(true);
        if (form.checkValidity() !== false) {

            this.onSaveClick();

        }
    };
    componentDidMount() {
        const { id } = this.props.match.params;
        if (id != null) {
            this.setState({ loading: true })
            this.props.getCall(id).then(() => {
                
            }).catch((err) => {
                
            }).finally(() => {
                this.setState({ loading: false })
            });
        }
        else {
            this.props.nfsReduc.nf = this.state.dc
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.nfsReduc.nf.id !== prevState.dc.id) {
            var { call } = nextProps.callsReduc;
            call = putBlankProperties(call)
            return {
                dc: call,
                validated: prevState.validated
            };
        }
        else

            return null
    }
    onChange = e => {
        var dc = { ...this.state.dc }
        dc[e.target.name] = e.target.value
        this.setState({ dc })

    };
    onChangeValue = (dc, e) => {
        dc[e.target.name] = e.target.value
        this.setState({ dc })
    }

    onSaveClick = (event) => {
        const nf = {
            number: this.state.dc.number,
            issued_at: this.state.dc.issued_at,
        };

        if (!this.state.dc.id) {
            this.upload(nf)
        }
        else {
            this.setState({ loading: true })
            var doc = { ...nf }
            doc = fixBlankProperties(doc)
            this.props.updateCall(this.state.dc.id, doc).then(() => {
                this.props.history.push('/manage/calls')
                this.setState({ loading: false });
            })
            .catch((err) => {

            })
        }
    };
    selectFile = (event) => {
        this.setState({
            selectedFiles: event.target.files,
        });
    }

    handleCloseDialog = ()  => {
        this.props.onClose()
    }

    upload = async (call) => {
        if (!this.state.selectedFiles){
            toastOnError("Selecione um arquivo")
        }
        else{
            let currentFile = this.state.selectedFiles[0];
            this.setState({
                progress: 0,
                currentFile: currentFile,
            });
            const fn = (event) => {
                this.setState({
                    progress: Math.round((100 * event.loaded) / event.total),
                });
            }
            const hash = await fileToSha256Hex(currentFile)
            this.setState({ loading: true })
            this.props.uploadCollect(currentFile, hash, call, fn)
                .then(() => {
                    this.setState({loading: false})
                    this.props.history.push('/manage/stocks');
                })
                .catch(() => {
                    this.setState({
                        loading: false,
                        progress: 0,
                        message: "Arquivo não foi carregado!",
                        currentFile: undefined,
                    });
                });
        }
    }

    render() {
        const {
            selectedFiles,
            currentFile,
            progress,
            message,
            fileInfos,
        } = this.state;

        return (
            <>
            <Loader loading={this.state.loading}/>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">Nota Fiscal</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form id="addNfForm" noValidate validated={this.state.validated}>
                                    {!this.props.nfsReduc.nf.id && <div>
                                        <div className="progress">
                                            <div
                                                className="progress-bar progress-bar-info progress-bar-striped"
                                                role="progressbar"
                                                aria-valuenow={progress}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{ width: progress + "%" }}
                                            >
                                                {progress}%
                                            </div>
                                        </div>

                                    </div>}
                                    {!this.props.nfsReduc.nf.id && <Form.Group as={Col} md="6" controlId="loadedImage">
                                        <Form.Label className="font-weight-bold">Arquivo: </Form.Label>
                                        <Form.Control type="file" accept=".xml" name="loadedImage" onChange={this.selectFile}
                                            style={{
                                                background: '#6C757D',
                                                width: '100%',
                                                height: '50%',
                                                color: 'white',
                                                padding: '2px',
                                                border: '2px solid #6C757D'
                                            }} />
                                    </Form.Group>}
                                    {this.props.nfsReduc.nf.file_path &&
                                        <>
                                            <Row>
                                                <Col md={1}>
                                                    <Form.Label className="font-weight-bold">Arquivo: </Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <a
                                                        target="_blank"
                                                        download
                                                        href={this.props.nfsReduc.nf.file_path}
                                                    >
                                                        <FileEarmarkArrowDownFill color="black" size={40} /></a>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Form>
                            </Card.Body>
                        </Card>
                        <><Button className="btn btn-primary active" type="button" onClick={this.handleSubmit} >
                            Salvar
                        </Button> {' '}</>
                        <Link to="/manage/stocks/" className="btn btn-danger active">
                            Voltar
                        </Link> {' '}
                        {/* <Link  className="btn btn-primary position-sticky fixed-button" to="/manage/stocks/editxml">
                            Importar Nota Fiscal
                        </Link>{' '}
                        <Link  className="btn btn-success position-sticky fixed-button" to="/manage/stocks/editxml?stock=OUT">
                            Gerar Saída de NF
                        </Link> */}

                    </Col>
                </Row>
            </Container>
            </>
        )
    }
}
AddNFXml.propTypes = {    
    nfsReduc: PropTypes.object.isRequired,
    uploadCollect: PropTypes.func.isRequired,
    getNf: PropTypes.func.isRequired,
    updateNf: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
    nfsReduc: state.nfsReduc
});

export default connect(mapStateToProps, {updateNf, getNf, uploadCollect })(withRouter(AddNFXml))