import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import React from 'react';
import { TrashFill } from 'react-bootstrap-icons';
import AutoCompleteNCM from 'components/utils/AutoCompleteNCM';
import ControlConcentration from 'components/utils/ControlConcentration';
import ControlDensity from 'components/utils/ControlDensity';
import { putBlankProperties, blobToBase64, convertB64ToBlob, fixBlankProperties } from "../../utils/Utils";
import ControlKilosLiters from 'components/utils/ControlKilosLiters';
import { addProduct, updateProduct, getProduct, getProducts } from "components/product/ProductActions";
import Stock from './Stock';
import PropTypes from 'prop-types';
import { onChangeKilos, onChangeLiters } from "../../utils/CalcLitersKilos"
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { cnpjMask, cnpjValidation, onlyNumber, densityMask, quantidadeMask, convertDecimalPlaces } from "../../utils/mask"
import { DateTime } from 'luxon';
import {datetimeFromIso} from "../../utils/Data"

class StockedProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            sps: [],
            nfrepo: {}
        };

        this.handleShow = this.handleShow.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.onChangeStockedProduct = this.onChangeStockedProduct.bind(this);
        this.onChangeNCMGeneral = this.onChangeNCMGeneral.bind(this);
        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.onChangeDensity = this.onChangeDensity.bind(this);
        this.onChangeKilos = this.onChangeKilos.bind(this);
        this.onChangeLiters = this.onChangeLiters.bind(this);
        this.onRemoveSpFromReview = this.onRemoveSpFromReview.bind(this);
        this.addSpToReview = this.addSpToReview.bind(this);
    }

    handleAddMovement = (newMovement) => {

        this.props.updateMovementsEdited(newMovement);
    };


    setValidated = (v) => {
        this.setState({ validated: v });
    }


    handleConfirm = (event) => {
        const form = document.getElementById("AddStock")
        for (var i = 0; i < this.state.sps.length; i++) {
            var item = this.state.sps[i]
            if (item.sp.liters[0] === "," || item.sp.kilos[0] === "," || item.pr.density[0] === ",") {
                toastOnError("Utilize vírgula apenas como separador decimal tanto para litros quanto para kilos")
                this.setValidated(false);
                form.checkValidity()
                return
            }
        }

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setValidated(true);
        if (form.checkValidity() !== false) {

            this.onSaveClick();
        }
    }
    onSaveClick = () => {
        const { sps } = this.state;
        const movementsToSave = sps.map((repo) => {
            let movement = null;
            
            movement = fixBlankProperties({
                id: repo.mv?.id,
                quantity_liters: repo.sp.liters,
                quantity_kilos: repo.sp.kilos,
                density: repo.pr.density,
                concentration: repo.pr.concentration,
                reference_date: datetimeFromIso(repo.mv?.reference_date),
                commercial_name: repo.pr.commercial_name,
                ncm_general: repo.pr.ncm_general,
                isLiters: repo.sp.isLiters,
            })
            

            return movement
        });
        


        this.handleAddMovement(movementsToSave);

        this.props.onClose();
    };

    handleShow() {
        this.setState({ show: true });
    }

    onChangeStockedProduct(e, index, type='pr') {
        const { products } = this.props.productsReduc
        var product = products.find(p => p.id === e.target.value)
        var sps = this.state.sps
        var spsIndexed = sps[index]
        var pr = { ...spsIndexed[type] }
        var sp = { ...spsIndexed['sp'] }
        pr[e.target.name] = e.target.value
        pr['density'] = product ? product.density : ''
        // pr['commercial_name'] = product ? product.commercial_name : ''
        sp['liters'] = ''
        sp['kilos'] = ''
        sps[index][type] = pr
        sps[index]['sp'] = sp
        this.setState({ sps })
    }

    onChangeMovement(e, index) {
        const { name, value } = e.target;
        this.setState((prevState) => {
            const updatedSps = [...prevState.sps];
            updatedSps[index].mv[name] = value;
            return { sps: updatedSps };
        });
    }

    onChangeNCMGeneral(e, index) {
        this.onChangeProduct(e, index)
        // Implement the logic to handle the change in NCM general
    }

    onChangeProduct(e, index) {
        const { name, value } = e.target;
        this.setState((prevState) => {
            const updatedSps = [...prevState.sps];
            updatedSps[index].pr[name] = value;
            return { sps: updatedSps };
        });
    }

    onChangeDensity = (e, index) => {
        // Máscara para formatação do valor de densidade (substitua densityMask se necessário)
        // e.target.value = densityMask(e.target.value);

        // Verifica se o valor começa com uma vírgula, e define a validação do formulário como inválida, se necessário
        if (e.target.value[0] === ",") {
            this.setValidated(false);
        }

        // Atualiza o array sps e os objetos `sp` e `pr` dentro dele
        const sps = [...this.state.sps];
        const spsIndexed = sps[index];
        const sp = { ...spsIndexed.sp };
        const pr = { ...spsIndexed.pr };

        // Atualiza o campo de densidade
        pr[e.target.name] = e.target.value;
        sps[index].pr = pr;

        // Define se `isLiters` é true ou false com base nos valores de `liters` e `kilos`
        if (sp.isLiters === null || sp.isLiters === "" || sp.isLiters === undefined) {
            sp.isLiters = sp.liters ? true : sp.kilos ? false : sp.isLiters;
        }

        // Atualiza o estado com o array `sps` atualizado
        this.setState({ sps }, () => {
            // Verifica `kilos` e `isLiters` para chamar onChangeKilos ou onChangeLiters conforme necessário
            if (sp.kilos && !sp.isLiters) {
                const kilos = sp.kilos;
                this.onChangeKilos({ target: { value: kilos } }, index);
            } else if (sp.liters && sp.isLiters) {
                const liters = sp.liters;
                this.onChangeLiters({ target: { value: liters } }, index);
            }
        });
    }

    onChangeLiters = (e, index) => {
        var kilos = onChangeLiters(e, this.state.sps[index].pr.density, this)
        this.onChangeProductForKilosLiters(e, index, 'sp', "kilos", kilos)
    }

    onChangeKilos = (e, index) => {
        var liters = onChangeKilos(e, this.state.sps[index].pr.density, this)
        this.onChangeProductForKilosLiters(e, index, 'sp', "liters", liters)
    }

    onChangeProductForKilosLiters = (e, index, type1 = 'sp', name = null, value = null, type2 = null) => {
        var sps = this.state.sps
        var spsIndexed = sps[index]
        var obj = { ...spsIndexed[type1] }
        // obj[e.target.name] = quantidadeMask(e.target.value)
        obj[e.target.name] = e.target.value
        sps[index][type1] = obj
        if (!type2) {
            type2 = type1
        }
        var obj2 = { ...spsIndexed[type2] }
        if (name) {
            obj2[name] = convertDecimalPlaces(value)
            if (name === 'liters' || name === 'kilos') {
                if (value) {
                    obj2['isLiters'] = name !== 'liters'
                }
                else {
                    obj2['isLiters'] = ""
                }
            }
            sps[index][type2] = obj2
        }
        this.setState({ sps });
    }   

    handleCancel(event) {

    }

    componentDidMount() {
        this.setState({ loading: true })
        this.props.getProducts({is_message_to_user:false})
            .catch((error) => {})
            .finally(() => {
                this.setState({ loading: false })
            });
    }

    onRemoveSpFromReview = (index) => {
        var sps = this.state.sps
        let movement = sps[index].mv
        sps.splice(index, 1)
        this.setState({ sps })
        this.props.onRemoveToEdit(movement)
    }

    componentDidUpdate(prevProps) {
        let sps = []
        this.props.movementsEdited?.forEach((movement) => {
            if (this.state.sps.filter(sp => sp.mv?.id === movement.id).length === 0) {
                sps.push({
                    ncmsynonyms: { options: [] },
                    pr: {
                        id: movement.product?.id,
                        name: "",
                        ncm_general: "",
                        commercial_name: movement.product?.commercial_name,
                        concentration: movement.product?.concentration,
                        density: movement.product?.density,
                        supplier: "",
                        enabled: true
                    },
                    sp: {
                        product: "",
                        liters: movement.quantity_liters,
                        kilos: movement.quantity_kilos,
                        isLiters: movement.mu === "kg" ? false : true,
                        nf: "",
                        enabled: true
                    },
                    mv: movement
                })
            }
        })
        if (sps && sps.length > 0) {
            sps = [...this.state.sps, ...sps]
            this.setState({ sps })
        }
    }

    addSpToReview() {
        var sps = this.state.sps
        sps.push({
            ncmsynonyms: { options: [] },
            pr: {
                name: "",
                ncm_general: "",
                commercial_name: "",
                concentration: "",
                density: "",
                supplier: "",
                enabled: true
            },
            sp: {
                product: "",
                liters: "",
                kilos: "",
                isLiters: "",
                nf: "",
                enabled: true
            }
        })
        this.setState({ sps })
    }

    render() {
        var i = 0;
        const { products } = this.props.productsReduc
        let products_options = products.map(c => {          
            var commercial_name = c.commercial_name ? "- " + c.commercial_name.toUpperCase() : ""  
            return <option key={c.id} value={c.id}>{ `${c.ncm_general_code} ${c.ncm_general_name} ${commercial_name} | Conc ${c.concentration}% - Dens ${c.density }` }</option>
        })

        return (
            <>
                <Button variant="info" className="btn btn-primary" onClick={() => { this.props.onShow(); this.handleShow(); }}>
                    Adicionar Item da Nota Fiscal
                </Button>
                <Dialog header="Retificações" maximized maximizable visible={this.props.show} style={{ width: '50vw' }} onHide={(event) => { this.props.onCancel(); this.handleCancel(event); }}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <h6>{this.props.title}</h6>

                            <Form id="AddStock" noValidate validated={this.state.validated}>

                                {this.state.sps.map((row, index) => {
                                    var form1 = <>
                                        <Form.Group key={i++} as={Col} md="6" controlId="id">
                                            <Form.Label key={i++} className="font-weight-bold" >Produto</Form.Label>
                                            <Form.Control key={i++}
                                                as="select" disabled required aria-label="Produto" name="id" onChange={e => this.onChangeStockedProduct(e, index)} value={row.pr.id}>
                                                <option value="">--- SELECIONE ---</option>
                                                {products_options}
                                            </Form.Control>
                                            <Form.Control.Feedback key={i++}>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback key={i++} type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" controlId="number">
                                            <Form.Label className="font-weight-bold">Data de Referência</Form.Label>
                                            <Form.Control required type="date" name="reference_date"
                                                value={DateTime.fromISO(row.mv?.reference_date, { setZone: true }).toFormat('yyyy-MM-dd')}
                                                onChange={e => this.onChangeMovement(e, index, 'mv')} maxLength={200} />
                                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </>

                                    var form2 = <>
                                        <Col md={"4"} key={i++}>
                                            <AutoCompleteNCM
                                                key={i++}
                                                name="ncm_general"
                                                required={true}
                                                onChange={(e) => this.onChangeNCMGeneral(e, index)}
                                                value={row.pr.ncm_general} />
                                        </Col>
                                        <Form.Group key={i++} as={Col} md="3" controlId="commercial_name">
                                            <Form.Label key={i++} className="font-weight-bold">Nome Comercial</Form.Label>
                                            <Form.Control key={i++} type='text' name="commercial_name" placeholder="Nome"
                                                onChange={e => this.onChangeProduct(e, index)}
                                                value={row.pr.commercial_name}
                                                maxLength={250} />
                                            <Form.Control.Feedback key={i++}>Ok</Form.Control.Feedback>
                                            <Form.Control.Feedback key={i++} type="invalid">
                                                Campo obrigatório.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group key={i++} as={Col} md="1" controlId="concentration">
                                            <ControlConcentration key={i++} value={row.pr.concentration} min={1} max={100}
                                                onChangeConcentration={e => this.onChangeProduct(e, index)} />
                                        </Form.Group>

                                        <Form.Group key={i++} as={Col} md="1" controlId="density">
                                            <ControlDensity key={i++} onChangeDensity={e => this.onChangeDensity(e, index)}
                                                value={row.pr.density} />
                                        </Form.Group>
                                    </>
                                    var righform = row.pr.id ? form1 : form2
                                    return (
                                        <Row key={i++} className="mb-3">
                                            {righform}
                                            <Form.Group key={i++} as={Col} md="1" controlId="kilos">
                                                <ControlKilosLiters key={i++} onChangeMU={e => this.onChangeKilos(e, index)}
                                                    req={!row.sp.liters && !row.sp.liters} dis={row.sp.isLiters && row.sp.liters !== ""}
                                                    value={row.sp.kilos} label="Kilos" name="kilos" />
                                            </Form.Group>

                                            <Form.Group key={i++} as={Col} md="1" controlId="liters">
                                                <ControlKilosLiters key={i++} onChangeMU={e => this.onChangeLiters(e, index)}
                                                    req={!row.sp.liters && !row.sp.liters} dis={!row.sp.isLiters && row.sp.kilos !== ""}
                                                    value={row.sp.liters} label="Litros" name="liters" />
                                            </Form.Group>

                                            <Col key={i++} md="1">
                                                <br />
                                                <Button variant="danger" className="btn btn-primary" onClick={() => this.onRemoveSpFromReview(index)}>
                                                    <TrashFill color="red" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    )
                                }
                                )}

                                <Row>
                                    <Button className="btn btn-light active" variant="success" type="button" onClick={this.addSpToReview} >+1 Novo Produto da Nota Fiscal</Button>
                                </Row>
                            </Form>
                        </div>
                    </div>
                    <br />
                    <div className="p-dialog-footer">
                        <Button onClick={(event) => { this.handleConfirm(event); }}>
                            Confirmar
                        </Button>
                        <Button className="btn btn-danger active" onClick={(event) => { this.props.onCancel(); this.handleCancel(event); }}>
                            Cancelar
                        </Button>
                    </div>
                </Dialog>
            </>
        );
    }
}

StockedProductModal.defaultProps = {
    show: false,
    title: "",
    nfrepo: {},
    products: []
}

StockedProductModal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    nfrepo: PropTypes.object,
    products: PropTypes.array,
    productsReduc: PropTypes.object.isRequired,
    onShow: PropTypes.func,
    onCancel: PropTypes.func,
    function: PropTypes.func,
    getProducts: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    productsReduc: state.productsReduc,
});

export default connect(mapStateToProps, { addProduct, updateProduct, getProduct, getProducts })(StockedProductModal);
